import { gql } from "@apollo/client";
import {
  SHARE_LINKS_ATTRIBUTES_PRIVATE,
  SHARE_LINK_FILES_ITEMS_ATTRIBUTES,
  UPLOAD_LINKS_ATTRIBUTES,
} from "./queries";

export const deleteShareLinkGql = gql`
  mutation($id: ID) {
    deleteShareLink(id: $id) {
      success
    }
  }
`;

export const deleteShareLinkFileGql = gql`
  mutation($id: ID, $page: Int, $pageSize: Int) {
    deleteShareLinkFile(id: $id, page: $page, pageSize:$pageSize) {
      success
      newPage {
        ${SHARE_LINK_FILES_ITEMS_ATTRIBUTES}
      }
    }
  }
`;

export const updateShareLinkkGql = gql`
  mutation($input: ShareLinkInput!) {
    updateShareLink(input: $input) {
      success
      shareLink {
        ${SHARE_LINKS_ATTRIBUTES_PRIVATE}
      }
    }
  }
`;

export const saveSharedLinkFilesToCloudGql = gql`
  mutation(
    $cloudStorageId: ID
    $sharedLinkToken: String
    $skipItemsIds: [String]
  ) {
    saveShareLinkFile(
      cloudStorageId: $cloudStorageId
      sharedLinkToken: $sharedLinkToken
      skipItemsIds: $skipItemsIds
    ) {
      success
      failedUploadFiles {
        id
        fileName
        fileType
        fileSize
        hasPreview
      }
    }
  }
`;

export const uploadPublicFileGql = gql`
  mutation($sessionId: String!, $file: Upload!) {
    uploadPublicFile(sessionId: $sessionId, file: $file) {
      result
    }
  }
`;

export const startUploadSessionGql = gql`
  mutation($title: String, $message: String, $password: String, $expiry: Int) {
    startPublicUploadSession(
      message: $message
      title: $title
      password: $password
      expiry: $expiry
    ) {
      shareLink {
        id
        filesCount
        filesTotalSize
        expiryDate
        title
        message
        token
        password
      }
    }
  }
`;

export const connectCloudToEmailGql = gql`
  mutation($integrationId: String) {
    connectCloudToEmail(integrationId: $integrationId) {
      success
      emailIntegration {
        id
        uploadsCount
        integrations {
          storageProvider
          destinationFolder {
            url
            name
          }
        }
      }
    }
  }
`;

export const deleteEmailUploadItemGql = gql`
  mutation($id: ID) {
    deleteEmailUploadItem(id: $id) {
      success
    }
  }
`;

export const deleteUploadItemGql = gql`
  mutation($id: ID) {
    deleteUploadItem(id: $id) {
      success
    }
  }
`;

export const updateUserInfoGql = gql`
  mutation($userInfo: UserInfoInput!) {
    updateUserInfo(userInfo: $userInfo) {
      result
    }
  }
`;

export const updateUsernameGql = gql`
  mutation($newUsername: String) {
    updateUsername(newUsername: $newUsername) {
      result
    }
  }
`;

export const updateUserAvatarGql = gql`
  mutation($avatarFile: Upload!) {
    updateUserAvatar(avatarFile: $avatarFile) {
      result
    }
  }
`;

export const updateUploadLinkLayoutSettingsGql = gql`
  mutation($input: UploadLinkLayoutSettingsInput!, $uploadLinkId: ID!) {
    updateUploadLinkLayoutSettings(input: $input, uploadLinkId: $uploadLinkId) {
      success
      uploadLinkLayoutSettings {
        showUserInfo
        description
        enableUserNameField
        enableUserEmailField
        enableUserMessageField
        messageTitle
        owner {
          firstName
          lastName
        }
      }
    }
  }
`;

export const updateUploadLinkGql = gql`
  mutation($input: UploadLinkInput!) {
    updateUploadLink(input: $input) {
      success
      uploadLink {
        ${UPLOAD_LINKS_ATTRIBUTES}
      }
    }
  }
`;

export const createUploadLinkGql = gql`
  mutation($integrationId: String, $name: String, $keepCopyInInternalStorage: Boolean) {
    createUploadLink(integrationId: $integrationId, name: $name, keepCopyInInternalStorage: $keepCopyInInternalStorage) {
      success
      error
      uploadLink {
        ${UPLOAD_LINKS_ATTRIBUTES}
      }
    }
  }
`;

export const uploadFileGql = gql`
  mutation(
    $input: UploadInput
    $token: String
    $expectedFilesCount: Int
    $currentFileIndex: Int
  ) {
    uploadFile(
      input: $input
      token: $token
      expectedFilesCount: $expectedFilesCount
      currentFileIndex: $currentFileIndex
    ) {
      result
    }
  }
`;
