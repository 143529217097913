import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

function createData(
  name: string,
  calories: string,
  fat: string,
  carbs: string
) {
  return { name, calories, fat, carbs };
}

const ComparisonTable = () => {
  const { t } = useTranslation();

  const rows = [
    createData(t("compare_external_storage"), "✔️", "✔️", "✔️"),
    createData(
      t("compare_number_external_storage"),
      "3",
      "10",
      t("unlimited_title")
    ),

    createData(
      t("compare_upload_links_count"),
      "1",
      "50",
      t("unlimited_title")
    ),
    createData(t("compare_file_sharing_size"), "1Gb", "50Gb", "100Gb"),
    createData(
      t("compare_share_link_expiry"),
      "14 " + t("days"),
      "30 " + t("days"),
      t("unlimited_title")
    ),
    createData(
      t("compare_downloads_from_share_link"),
      t("unlimited_title"),
      t("unlimited_title"),
      t("unlimited_title")
    ),
    createData(t("compare_uploadly_storage"), "1 Gb", "10 Gb", "100 Gb"),

    createData(
      t("compare_attachements_upload"),
      "10",
      t("unlimited_title"),
      t("unlimited_title")
    ),
    createData(
      t("compare_number_uploads_collect_link"),
      "100",
      "500",
      t("unlimited_title")
    ),
    createData(t("compare_file_size_per_upload_link"), "1Gb", "10Gb", "100Gb"),
    /* createData(
      t("compare_pricing_speech_to_text_notes_title"),
      "✔️",
      "✔️",
      "✔️"
    ),
    createData(t("compare_pricing_privacy_filter_title"), "✔️", "✔️", "✔️"),
    createData(t("compare_pricing_chrome_extension_title"), "✔️", "✔️", "✔️"),
    createData(
      t("compare_pricing_recap_notifications_title"),
      "✔️",
      "✔️",
      "✔️"
    ),
    createData(t("compare_pricing_global_search_title"), "✔️", "✔️", "✔️"),
    createData(t("compare_pricing_shared_collections_title"), "✔️", "✔️", "✔️"),
    createData(t("compare_pricing_send_email_title"), "✔️", "✔️", "✔️"),
    createData(t("compare_pricing_public_collections_title"), "✔️", "✔️", "✔️"),
    createData(t("compare_pricing_public_profile_title"), "✔️", "✔️", "✔️"),
    createData(t("compare_pricing_import_pins_pinterest"), "", "✔️", "✔️"),
    createData(
      t("compare_pricing_connect_collection_to_notion"),
      "",
      "✔️",
      "✔️"
    ),
    createData(
      t("compare_pricing_highlights_extractions_title"),
      "",
      "✔️",
      "✔️"
    ),
    createData(t("compare_pricing_readonly_users_title"), "", "✔️", "✔️"),
    createData(t("compare_pricing_no_ads_title"), "", "✔️", "✔️"),
    createData(t("compare_pricing_fingerprint_lock_title"), "", "✔️", "✔️"),
    createData(t("compare_pricing_export_collection_title"), "", "", "✔️"),
    createData(
      t("compare_pricing_quick_access_notification_title"),
      "",
      "",
      "✔️"
    ),
    createData(
      t("compare_pricing_auto_collection_organizing_title"),
      "",
      "",
      "✔️"
    ),
    createData(t("compare_pricing_auto_tag_organizing_title"), "", "", "✔️"),
    createData(t("compare_pricing_virtual_assistant_title"), "", "", "✔️"),
    createData(t("compare_pricing_smart_note_editor_title"), "", "", "✔️"),
    createData(t("compare_pricing_new_feature_access_title"), "", "", "✔️"), */
  ];

  const getColumnTitleComponent = React.useCallback((title: string) => {
    return (
      <Typography
        align="center"
        color="text.primary"
        gutterBottom
        sx={{
          fontFamily: "Trebuchet MS, sans-serif",
        }}
      >
        {title}
      </Typography>
    );
  }, []);

  const getRowText = React.useCallback((text: string) => {
    return (
      <Typography
        color="text.primary"
        gutterBottom
        sx={{
          fontFamily: "Trebuchet MS, sans-serif",
          paddingLeft: "10%",
          alignText: "left",
        }}
      >
        {text}
      </Typography>
    );
  }, []);

  const getCellContentText = React.useCallback((text: string) => {
    return (
      <Typography
        align="center"
        color="text.primary"
        gutterBottom
        sx={{
          fontFamily: "Trebuchet MS, sans-serif",
        }}
      >
        {text}
      </Typography>
    );
  }, []);

  return (
    <>
      {" "}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            width: "60%",
            display: "flex",
            backgroundColor: "transparent",
            marginBottom: "48px",
            "@media (max-width: 780px)": {
              width: "100%",
            },
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">
                  {getColumnTitleComponent(t("tier_free_title"))}
                </TableCell>
                <TableCell align="right">
                  {getColumnTitleComponent(t("tier_standard_title"))}
                </TableCell>
                <TableCell align="right">
                  {getColumnTitleComponent(t("tier_business_title"))}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="left"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {getRowText(row.name)}
                  </TableCell>
                  <TableCell align="right">
                    {getCellContentText(row.calories)}
                  </TableCell>
                  <TableCell align="right">
                    {getCellContentText(row.fat)}
                  </TableCell>
                  <TableCell align="right">
                    {getCellContentText(row.carbs)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ComparisonTable;
