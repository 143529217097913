import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  LinearProgress,
  Link,
  Switch,
  TextField,
  Tooltip,
  Typography,
  alpha,
  keyframes,
  useTheme,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  getRemainingTime,
  getSizeInHumeanReadableFormat,
} from "shared/dataUtils";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useMutation } from "@apollo/client";
import { startUploadSessionGql, uploadPublicFileGql } from "graphql/mutations";
import { DEBUG, getPublicShareLinkFromToken } from "configuration";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

import animationData from "res/animations/complete_animation.json";
import Lottie from "lottie-react";
import { ShareLink } from "models/shareLink";
import ShareLinkPreviewMp4 from "res/gifs/preview_share_file_online.mp4";
import { useResponsive } from "hooks/use-responsive";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "navigation/Constants";
import LockIcon from "@mui/icons-material/Lock";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircleIcon from "@mui/icons-material/Circle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
const rippleEffect = keyframes`
    0% {
      box-shadow: 0 0 0 0 #1877f2;
    }
    70% {
      box-shadow: 0 0 0 10px rgba(0, 0, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 255, 0);
    }
  `;

interface UploadableFile {
  fileObj: File;
  progress: number;
  isUploaded: boolean;
}
const UploadFilesContainer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [
    fileBeingUploaded,
    setFileBeingUploaded,
  ] = React.useState<UploadableFile | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const abort = React.useRef<any>();
  const [uploadOngoing, setUploadOngoing] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [passwordProtectionEnabled, setPasswordEnabledProtection] = useState(
    false
  );
  const [showPassword, setShowPassword] = React.useState(false);
  const [linkPassword, setLinkPassword] = React.useState("");

  const titleRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLInputElement>(null);
  const downMd = useResponsive("down", "md");

  const navigate = useNavigate();
  const [
    expiryMenuAnchorElement,
    setExpiryMenuAnchorElement,
  ] = React.useState<null | HTMLElement>(null);

  const closeMenu = useCallback(() => {
    setExpiryMenuAnchorElement(null);
  }, [setExpiryMenuAnchorElement]);

  const [uploadFile, uploadFileResult] = useMutation(uploadPublicFileGql, {
    context: {
      clientName: "public",
      fetchOptions: {
        useUpload: true,
        onProgress: (ev: ProgressEvent) => {
          console.log("Got new progress event");
          const progress = ev.loaded / ev.total;
          if (fileBeingUploaded) {
            fileBeingUploaded.progress = progress;
            //setFileBeingUploaded(fileBeingUploaded)
          }
          setProgress(ev.loaded / ev.total);
        },
        onAbortPossible: (abortHandler: any) => {
          abort.current = abortHandler;
        },
      },
    },
  });

  const [startUploadSession, startUploadSessionResult] = useMutation(
    startUploadSessionGql,
    {
      context: {
        clientName: "public",
      },
    }
  );

  function onFileInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const filesList = e.target.files;
    if (!filesList) {
      return;
    }

    var totalfilesSize = 0;
    var totalfilesCount = 0;
    var uploadableFilesList: UploadableFile[] = Array.from(filesList).map(
      (file: File) => {
        totalfilesSize += file.size;
        totalfilesCount++;
        const uploadableFileObject = {
          fileObj: file,
          progress: 0,
          isUploaded: false,
        };
        return uploadableFileObject;
      }
    );

    setSelectedFiles([...uploadableFilesList, ...selectedFiles]);
  }

  const handleRemoveFile = React.useCallback(
    (fileToRemove: UploadableFile) => {
      const newArray = selectedFiles.filter(
        (file: UploadableFile) => file !== fileToRemove
      );
      setSelectedFiles(newArray);
    },
    [selectedFiles]
  );

  const doUploadFiles = useCallback(
    async (sessionId: string) => {
      if (!selectedFiles) {
        return;
      }
      var i = 0;
      for (const uploadableFile of selectedFiles) {
        i++;
        if (uploadableFile.isUploaded) {
          if (DEBUG) {
            console.log("Skipping file %o", uploadableFile.fileObj);
          }
          continue;
        }

        const fileObj = uploadableFile.fileObj;
        if (DEBUG) {
          console.log(
            "Adding   new file " +
              JSON.stringify(fileObj.type) +
              " size " +
              JSON.stringify(fileObj.size)
          );
        }
        setFileBeingUploaded(uploadableFile);
        var uploadInput = {
          sessionId: sessionId,
          file: fileObj,
        };

        await uploadFile({
          variables: {
            ...uploadInput,
          },
        }).then((result) => {
          if (result.data.uploadPublicFile.result) {
            uploadableFile.isUploaded = true;
            // setUploadedFilesList([fileObj, ...uploadedFilesList]);
          }
        });
      }
      setUploadOngoing(false);
      setUploadCompleted(true);
      //const filesCount = files.length;
    },
    [selectedFiles, setUploadOngoing, setUploadCompleted, setFileBeingUploaded]
  );

  const startUpload = useCallback(() => {
    setUploadOngoing(true);

    const title = titleRef.current?.value;
    const message = messageRef.current?.value;
    startUploadSession({
      variables: {
        title: title?.trim(),
        message: message?.trim(),
        password:
          passwordProtectionEnabled && linkPassword ? linkPassword : null,
      },
    }).then((result) => {
      if (
        result.data &&
        result.data.startPublicUploadSession &&
        result.data.startPublicUploadSession.shareLink
      ) {
        const sessionId = result.data.startPublicUploadSession.shareLink.id;
        doUploadFiles(sessionId);
      }
    });
  }, [
    startUploadSession,
    titleRef.current,
    messageRef.current,
    doUploadFiles,
    passwordProtectionEnabled,
    linkPassword,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      }}
    >
      {!uploadCompleted && (
        <>
          <Box
            sx={{
              width: !downMd || selectedFiles.length > 0 ? "50%" : "100%",
              display: "flex",
              flexDirection: "column",
              px: theme.spacing(4),
              [theme.breakpoints.down("sm")]: {
                display: selectedFiles.length > 0 ? "none" : "flex",
              },
            }}
          >
            <Box
              sx={{
                marginTop: theme.spacing(4),
                px: theme.spacing(4),
                display: "flex",
                flexDirection: "row",
              }}
            >
              <input
                ref={hiddenFileInput}
                accept={"*/*"}
                onChange={onFileInputChange}
                type="file"
                multiple
                style={{ display: "none" }}
              />
              {!uploadOngoing && (
                <>
                  <IconButton
                    sx={{
                      position: "relative",
                      borderRadius: "50%",
                      padding: 0,
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        width: "56px",
                        height: "56px",
                        alignItems: "center",
                        borderRadius: "50%",
                        animation: `${rippleEffect} 2s infinite`,
                      },
                    }}
                    onClick={() => {
                      hiddenFileInput?.current?.click();
                    }}
                  >
                    <AddCircleIcon
                      sx={{
                        width: "48px",
                        height: "48px",
                        color: theme.palette.primary.main,
                      }}
                    />
                  </IconButton>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mx: theme.spacing(2),
                    }}
                  >
                    <Typography variant="h5" sx={{}}>
                      {t("upload_files_title")}
                    </Typography>
                    <Typography variant="caption" sx={{}}>
                      {t("upload_files_subtitle")}. &nbsp;
                      {t("need_more_question")}
                      <Link
                        sx={{
                          marginLeft: theme.spacing(1),
                          marginRight: theme.spacing(1),
                          cursor: "pointer",
                        }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          navigate(LOGIN);
                        }}
                      >
                        {t("login")}
                      </Link>
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        marginTop: theme.spacing(1),
                      }}
                    ></Typography>
                  </Box>
                </>
              )}
              {uploadOngoing && (
                <>
                  <CircularProgress></CircularProgress>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      mx: theme.spacing(2),
                    }}
                  >
                    <Typography variant="body1" sx={{}}>
                      {t("file_upload_ongoing_message")}
                    </Typography>
                    {/*  <Typography variant="caption" sx={{}}>
                          {t("upload_files_subtitle")}
                        </Typography> */}
                  </Box>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: theme.spacing(6),
              }}
            >
              <TextField
                inputRef={titleRef}
                name="title"
                disabled={uploadOngoing}
                placeholder={t("title")}
                sx={{
                  marginTop: theme.spacing(2),
                }}
              />
              <TextField
                inputRef={messageRef}
                name="message"
                disabled={uploadOngoing}
                placeholder={t("message_title")}
                multiline
                rows={4} // Number of initial rows
                maxRows={20} // Maximum number of rows before scrolling
                sx={{
                  marginTop: theme.spacing(2),
                  "& .MuiInputBase-input::placeholder": {},
                  padding: 0,
                  "& .MuiInputBase-root": {
                    padding: "8px",
                  },
                  "& .MuiInputBase-input": {
                    padding: 0,
                    alignContent: "top",
                    textAlign: "top",
                  },
                }}
              />

              <Box
                sx={{
                  paddingTop: theme.spacing(3),
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      flex: "1",
                      boxSizing: "border-box",
                      wordWrap: "break-word",
                      color: "gray",
                    }}
                    variant="subtitle2"
                  >
                    {t("password_protection_title")}
                  </Typography>
                </Box>

                <Switch
                  defaultChecked={false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    // onStateChanged(event.target.checked);
                    setPasswordEnabledProtection(event.target.checked);
                  }}
                />
              </Box>
              <TextField
                sx={{
                  display: passwordProtectionEnabled ? "block" : "none",
                  marginTop: theme.spacing(2),
                  transition: theme.transitions.create("all", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                }}
                fullWidth
                value={linkPassword}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLinkPassword(event.target.value);
                }}
                placeholder={t("share_link_password_hint")}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                sx={{
                  marginTop: theme.spacing(2),
                }}
              >
                <Typography variant="caption">
                  {t("link_expiry_hint_landing")}
                  <Link
                    sx={{
                      marginLeft: theme.spacing(1),
                      marginRight: theme.spacing(1),
                      cursor: "pointer",
                    }}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      navigate(LOGIN);
                    }}
                  >
                    {t("login")}
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: downMd || selectedFiles.length > 0 ? "none" : "flex",
              width: "70%",
              height: "100%",
              flexDirection: "column",
              justifyContent: "top",
              alignItems: "center",
              overflow: "hidden",
              padding: theme.spacing(2),
            }}
          >
            <Typography
              variant="h6"
              sx={{
                marginBottom: theme.spacing(1),
                width: "70%",
                textAlign: "center",
              }}
            >
              {t("share_links_connected_to_cloud")}
            </Typography>
            <video
              autoPlay
              loop
              muted
              style={{
                width: "100%",
                height: "80%",
                borderRadius: "10px",
                outline: "1px solid",
                objectFit: "cover",
                marginBottom: theme.spacing(1),
                outlineColor:
                  theme.palette.mode === "light"
                    ? alpha("#BFCCD9", 0.5)
                    : alpha("#9CCCFC", 0.1),
              }}
              src={ShareLinkPreviewMp4}
            >
              <source
                src={ShareLinkPreviewMp4}
                type="video/mp4"
                height={"100%"}
              />
              Your browser does not support the video tag.
            </video>
          </Box>
        </>
      )}
      {uploadCompleted &&
        startUploadSessionResult.data?.startPublicUploadSession && (
          <SharelinkView
            shareLink={
              startUploadSessionResult.data.startPublicUploadSession.shareLink
            }
            password={
              passwordProtectionEnabled && linkPassword
                ? linkPassword
                : undefined
            }
          />
        )}

      <Box
        sx={{
          width: selectedFiles.length > 0 ? "100%" : "0%",
          maxWidth: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          "&::-webkit-scrollbar": {
            width: 3,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px #faf6e9`,
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "4px",
          },
          overflowY: "auto",
          [theme.breakpoints.down("sm")]: {
            width: selectedFiles.length > 0 ? "100%" : "0%",
            display: uploadCompleted ? "none" : "block",
            maxWidth: "100%",
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            overflowY: "auto",
            flex: 1
          }}
        >
          {selectedFiles.map((uploadableFile: UploadableFile) => {
            return (
              <UploadFilePreviewItem
                fileBeingUploaded={fileBeingUploaded}
                uploadableFile={uploadableFile}
                isUploaded={uploadableFile.isUploaded}
                onDelete={handleRemoveFile}
                progress={progress}
              />
            );
          })}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            px: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        >
          <Button
            onClick={() => {
              startUpload();
            }}
            variant="contained"
          >
            {t("upload_action")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

interface SharelinkViewProps {
  shareLink: ShareLink;
  password?: string;
}
const SharelinkView = ({ shareLink, password }: SharelinkViewProps) => {
  const [displayUrlCopiedTooltip, setDisplayUrlCopiedTooltip] = React.useState(
    false
  );
  const [
    displayPasswordCopiedTooltip,
    setDisplayPasswordCopiedTooltip,
  ] = React.useState(false);

  const copyLinkToClipboard = React.useCallback(() => {
    navigator.clipboard.writeText(getPublicShareLinkFromToken(shareLink.token));
    setDisplayUrlCopiedTooltip(true);
    setTimeout(() => {
      setDisplayUrlCopiedTooltip(false);
    }, 2000);
  }, [shareLink]);

  const copyPasswordToClipboard = React.useCallback(() => {
    if (!password) {
      return;
    }
    navigator.clipboard.writeText(password);
    setDisplayPasswordCopiedTooltip(true);
    setTimeout(() => {
      setDisplayPasswordCopiedTooltip(false);
    }, 2000);
  }, [shareLink]);
  const theme = useTheme();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "50%",
        height: "20%",
        justifyContent: "top",
        alignItems: "center",
        // paddingTop: "10%",
        px: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      }}
    >
      <Lottie
        style={{ height: "200px", width: "200px" }}
        animationData={animationData}
        loop={true}
        autoplay
      />
      <Typography>{t("share_link_ready_message")} 👇</Typography>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          px: theme.spacing(1),
          marginTop: "16px",
          marginBottom: "16px",
          alignItems: "center",
          border: "dashed",
          borderRadius: "8px",
          borderColor: theme.palette.primary.main,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            overflow: "auto",
            scrollbarWidth: "1px",
            "&::-webkit-scrollbar": {
              height: 0,
            },
          }}
        >
          <InsertLinkIcon></InsertLinkIcon>
          <Typography
            sx={{
              marginLeft: "16px",
              wordWrap: "break-word",
            }}
          >
            {getPublicShareLinkFromToken(shareLink.token)}
          </Typography>
        </Box>
        <Tooltip open={displayUrlCopiedTooltip} title={t("link_copied_msg")}>
          <Button
            sx={{
              color: "gray",
              fontSize: ".7rem",
            }}
            onClick={copyLinkToClipboard}
          >
            {t("copy_link_action")}
          </Button>
        </Tooltip>
      </Box>

     
      {password && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            border: "dashed",
            borderRadius: "8px",
            borderColor: theme.palette.primary.main,
          }}
        >
          <LockIcon
            sx={{
              marginLeft: theme.spacing(1),
              color: "gray",
            }}
          />
          {/* <Typography>Link password</Typography> */}
          <Typography>
            {showPassword
              ? password
              : password.split("").map(() => (
                  <CircleIcon
                    sx={{
                      width: ".8rem",
                      height: ".8rem",
                    }}
                  />
                ))}
          </Typography>
          <Box>
            <Tooltip
              open={displayPasswordCopiedTooltip}
              title={t("password_copied")}
            >
              <IconButton
                onClick={() => {
                  copyPasswordToClipboard();
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
            {/*   <IconButton>
              <DownloadIcon />
            </IconButton> */}
            <IconButton
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {!showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Box>
        </Box>
      )}
       <Typography
        variant="caption"
        sx={{
          width: "100%",
          marginTop: theme.spacing(2),
          px: theme.spacing(2),
        }}
      >
        {t("expires_in", {
          expiry: getRemainingTime(shareLink.expiryDate, t),
        })}
      </Typography>
    </Box>
  );
};

interface UploadFilePreviewItemProps {
  uploadableFile: UploadableFile;
  fileBeingUploaded: UploadableFile | null;
  onDelete: (file: UploadableFile) => void;
  progress?: number;
  isUploaded: boolean;
}

const shouldDisplayItemPreview = (fileObj: File): boolean => {
  return fileObj.type.startsWith("image/");
};

const UploadFilePreviewItem = ({
  uploadableFile,
  fileBeingUploaded,
  onDelete,
  progress = 0,
  isUploaded,
}: UploadFilePreviewItemProps) => {
  const theme = useTheme();
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [localProgress, setLocalProgress] = useState<number>(0);
  const [displayPreview, setDisplayPreview] = useState(
    shouldDisplayItemPreview(uploadableFile.fileObj)
  );

  const { t } = useTranslation();
  React.useEffect(() => {
    setLocalProgress(progress);
  }, [progress]);

  React.useEffect(() => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageURL(reader.result as string);
    };
    reader.readAsDataURL(uploadableFile.fileObj);
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          overflow: "hidden",
          marginTop: theme.spacing(2),
          alignItems: "center",
        }}
      >
        {/*      {imageURL && displayPreview && (
              <img
                  style={{
                  objectFit: "cover",
                  height: "40px",
                  width: "40px",
                  borderRadius: "4px"
                  }}
                  height="40px"
                  width="40px"
                  src={imageURL}
              />
              )}
              {!imageURL && displayPreview && (
              <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height="100px"
              />
              )} */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // marginLeft: theme.spacing(1),
            width: "90%",
          }}
        >
          <Typography
            sx={{
              fontSize: ".9rem",
              fontWeight: "bold",
            }}
            noWrap
          >
            {uploadableFile.fileObj.name}
          </Typography>
          <Typography variant="caption">
            {getSizeInHumeanReadableFormat(uploadableFile.fileObj.size)}
            {" • " + uploadableFile.fileObj.type}
          </Typography>
        </Box>

        {isUploaded ? (
          <CheckCircleIcon
            sx={{
              color: "green",
              marginLeft: theme.spacing(1),
            }}
          />
        ) : (
          <>
            {uploadableFile == fileBeingUploaded ? (
              <>
                {localProgress == 1 ? (
                  <CircularProgress size={"24px"} variant="indeterminate" />
                ) : (
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: ".8rem",
                    }}
                  >
                    {`${Math.round(localProgress * 100)}%`}
                  </Typography>
                )}
              </>
            ) : (
              <Tooltip title={t("delete_action")}>
                <IconButton
                  sx={{
                    flex: "1",
                  }}
                  onClick={() => {
                    onDelete(uploadableFile);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </Box>
      {uploadableFile == fileBeingUploaded && !uploadableFile.isUploaded && (
        <LinearProgress
          sx={{
            width: "100%",
          }}
          defaultValue={0}
          value={localProgress * 100}
          variant="determinate"
        />
      )}
    </Box>
  );
};

export default React.memo(UploadFilesContainer);
