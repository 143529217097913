import * as React from "react";
import {
  Card,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
  alpha,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ConnectedStoragesIllustration from "res/illustrations/connected_storages.svg";
import FileSharingIllustration from "res/illustrations/file_sharing_upload.svg";
import LandingUploadlyPreview from "res/gifs/landing_uploadly_preview.gif";
import LandingUploadlyPreviewMP4 from "res/gifs/landing_uploadly_preview.mp4";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "navigation/Constants";
import { useDispatch } from "react-redux";
import { startGooglelogin } from "store/view";
import DownloadUploadContainer from "./DownloadUploadContainer";

export default function Hero() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        /*  backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`, */
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
          overflow: "hidden",
          background: "aliceblue",
          px: theme.spacing(16),
          [theme.breakpoints.down("lg")]: {
            px: theme.spacing(0),
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            marginTop: theme.spacing(8),
            px: theme.spacing(8),
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              px: theme.spacing(4),
            },
          }}
        >
          <Box
            sx={{
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
              },
            }}
          >
            <Stack
              spacing={2}
              display={"flex"}
              justifyContent={"center"}
              sx={{
                height: "100%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  position: "relative",
                  flexDirection: { xs: "column", md: "row" },
                  textAlign: "start",
                  fontSize: "clamp(3.5rem, 10vw, 4rem)",
                  color: "#0b3558",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "clamp(1.5rem, 10vw, 2rem)",
                  },
                }}
              >
                {t("main_landing_title_part1")}
                <Typography
                  component="span"
                  variant="h3"
                  sx={{
                    fontSize: "clamp(3rem, 10vw, 4rem)",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "primary.main"
                        : "primary.light",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "clamp(1.5rem, 10vw, 2rem)",
                    },
                  }}
                >
                  {t("main_landing_title_part2")}{" "}
                </Typography>
              </Typography>

              <Typography
                textAlign="start"
                sx={{
                  alignSelf: "start",
                  px: theme.spacing(4),
                  marginTop: theme.spacing(4),
                  width: { sm: "100%", md: "80%" },
                  [theme.breakpoints.down("sm")]: {
                    px: theme.spacing(1),
                  },
                }}
              >
                {t("main_landing_subtitle1")}
              </Typography>
              <Typography
                textAlign="start"
                sx={{
                  alignSelf: "start",
                  px: theme.spacing(4),
                  width: { sm: "100%", md: "80%" },
                  [theme.breakpoints.down("sm")]: {
                    px: theme.spacing(1),
                  },
                }}
              >
                {t("main_landing_subtitle2")}
              </Typography>
              <Typography
                textAlign="start"
                sx={{
                  alignSelf: "start",
                  px: theme.spacing(4),
                  width: { sm: "100%", md: "80%" },
                  [theme.breakpoints.down("sm")]: {
                    px: theme.spacing(1),
                  },
                }}
              >
                {t("main_landing_subtitle3")}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              maxWidth: "70%",
              height: "300px",
              [theme.breakpoints.down("sm")]: {
                maxWidth: "100%",
              },
            }}
          >
            <img
              src={ConnectedStoragesIllustration}
              style={{
                width: "100%",
                height: "100%"
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <DownloadUploadContainer />
        </Box>
      </Box>
       <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 7, sm: 10 },
          pb: { xs: 4, sm: 12 },
        }}
      >
       {/*  <Stack
          useFlexGap
          sx={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          <Stack
            spacing={2}
            useFlexGap
            justifyContent={"start"}
            sx={{ width: "100%" }}
          >
            <Typography
              variant="h2"
              sx={{
                position: "relative",
                flexDirection: { xs: "column", md: "row" },
                alignSelf: "center",
                textAlign: "start",
                fontSize: "clamp(3.5rem, 10vw, 4rem)",
                color: "#0b3558",
                [theme.breakpoints.down("md")]: {
                  fontSize: "clamp(1.5rem, 10vw, 2rem)",
                },
              }}
            >
              {t("landing_main_title_1")}&nbsp;
              <Typography
                component="span"
                variant="h2"
                sx={{
                  fontSize: "clamp(3rem, 10vw, 4rem)",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "primary.main"
                      : "primary.light",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "clamp(1.5rem, 10vw, 2rem)",
                  },
                }}
              >
                {t("landing_main_title_2")}
              </Typography>
            </Typography>
            <Typography
              textAlign="start"
              color="text.secondary"
              sx={{ alignSelf: "start", width: { sm: "100%", md: "80%" } }}
            >
              {t("landing_main_description")}
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignSelf="start"
              spacing={1}
              useFlexGap
              sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textTransform: "uppercase",
                  padding: theme.spacing(1),
                  px: theme.spacing(3),
                  [theme.breakpoints.down("md")]: {
                    display: "none",
                  },
                }}
                onClick={() => {
                  dispatch(startGooglelogin(true));
                  navigate(LOGIN);
                }}
              >
                <Box
                  sx={{
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px",
                    borderRadius: "4px",
                    marginRight: theme.spacing(1),
                  }}
                >
                  <img src={GoogleIcon} />
                </Box>{" "}
                {t("singup_with_google")}
              </Button>
            </Stack>
          </Stack>

          <img
            src={ConnectedStoragesIllustration}
            style={{
              width: "100%",
              height: "300px",
            }}
          />
        </Stack>
        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: "uppercase",
            padding: theme.spacing(1),
            px: theme.spacing(3),
            display: "none",
            marginTop: theme.spacing(4),
            [theme.breakpoints.down("md")]: {
              display: "flex",
            },
          }}
        >
          <Box
            sx={{
              background: "white",
              display: "flex",
              justifyContent: "center",
              padding: "4px",
              borderRadius: "4px",
              marginRight: theme.spacing(1),
            }}
          >
            <img src={GoogleIcon} />
          </Box>{" "}
          {t("singup_with_google")}
        </Button> */}
        <Typography
          variant="h3"
          sx={{
            position: "relative",
            flexDirection: { xs: "column", md: "row" },
            alignSelf: "center",
            textAlign: "center",
            fontSize: "clamp(3.5rem, 10vw, 4rem)",
            color: "#0b3558",
            mt: { xs: 2, sm: 4 },
            [theme.breakpoints.down("md")]: {
              fontSize: "clamp(1.5rem, 10vw, 2rem)",
            },
          }}
        >
          {t("landing_simple_interface_ui")}
        </Typography>
        <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 3, sm: 4 },
            alignSelf: "center",
            width: "90%",
            height: "auto",

            backgroundSize: "cover",
            borderRadius: "10px",
            outline: "1px solid",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BFCCD9", 0.5)
                : alpha("#9CCCFC", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha(theme.palette.primary.main, 0.4)}`
                : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
          })}
        >
      
          <video
            autoPlay
            loop
            muted
            style={{
              width: "100%",
              height: "auto",
              backgroundImage:
                theme.palette.mode === "light"
                  ? 'url("/static/images/templates/templates-images/hero-light.png")'
                  : 'url("/static/images/templates/templates-images/hero-dark.png")',
              backgroundSize: "cover",
              borderRadius: "10px",
              outline: "1px solid",
              outlineColor:
                theme.palette.mode === "light"
                  ? alpha("#BFCCD9", 0.5)
                  : alpha("#9CCCFC", 0.1),
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                  : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
            }}
            src={LandingUploadlyPreviewMP4}
          >
            <source src={LandingUploadlyPreviewMP4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      </Container>
    </Box>
  );
}
