import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  retrieveFilesForShareLinkGql,
  retrieveShareLinkGql,
} from "graphql/queries";
import React, { useCallback, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  getRemainingTime,
  getSizeInHumeanReadableFormat,
} from "shared/dataUtils";
import { useTranslation } from "react-i18next";
import { ShareLink, ShareLinkFile } from "models/shareLink";
import { getSharedFileDownloadLink, getSharedFilePreview } from "configuration";

import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import GoogleDriveIcon from "res/icons/google-drive-icon.png";
import DropBoxIcon from "res/icons/dropbox-logo.svg";
import OneDriveIcon from "res/icons/one-drive-logo.svg";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ShareLinkPageHeader from "components/ui/ShareLinkPageHeader";
import { Helmet } from "react-helmet-async";
import SaveSharedFileToCloud from "components/modals/SaveSharedFileToCloud";
import { isUserLoggedIn } from "localstorage/localstorage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setPathAfterLogin } from "store/view";
import { LOGIN, ROOT } from "navigation/Constants";
import PageNotFound from "./PageNotFound";
import { ShareLlinkCheckResult, checkShareLinkStatus } from "api/shareLinkApi";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";

const ShareLinkPage = () => {
  const params: any = useParams();
  const containerRef = useRef(null);
  const theme = useTheme();
  const [openSaveToCloudModal, setOpenSaveToCloudModal] = useState(false);
  const [shareLinkLocked, setShareLinkLocked] = useState(false);
  const [linkExpiredOrNotExists, setLinkExpiredOrNotExists] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const isUserLoggedIn = useSelector(
    (state: RootState) => state.auth.isUserLoggedIn
  );

  const [passwordInput, setPasswordInput] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const [retrieveShareLink, retrieveShareLinkResult] = useLazyQuery(
    retrieveShareLinkGql,
    {
      variables: {
        token: params.token,
        password: "",
      },
      context: {
        clientName: "public",
      },
    }
  );

  const [retrieveShareLinkFiles, retrieveShareLinkFilesResult] = useLazyQuery(
    retrieveFilesForShareLinkGql,
    {
      variables: {
        token: params.token,
        password: null,
      },
      context: {
        clientName: "public",
      },
    }
  );

  React.useEffect(() => {
    const token = params.token;
    if (token) {
      checkShareLinkStatus(token).then((result: ShareLlinkCheckResult) => {
        if (result.disabled || result.expired) {
          setLinkExpiredOrNotExists(true);
        } else if (result.locked) {
          setShareLinkLocked(true);
        } else {
          retrieveShareLink()
            .then((result) => {
              if (!result.data || !result.data.shareLink) {
                setLinkExpiredOrNotExists(true);
              } else {
                retrieveShareLinkFiles();
              }
            })
            .catch((e) => {
              console.log("Caught an exception with the link" + e);
              setLinkExpiredOrNotExists(true);
            });
        }
      });
    }
  }, []);

  //console.log("GOT THE TOKEN HERE " + params.token);

  const getIconForFileType = useCallback((fileType: string) => {
    if (fileType.startsWith("image/")) {
      return <ImageOutlinedIcon />;
    } else if (fileType.startsWith("video/")) {
      return <VideocamOutlinedIcon />;
    } else if (fileType.startsWith("application/pdf")) {
      return <PictureAsPdfOutlinedIcon />;
    } else if (fileType.startsWith("application/zip")) {
      return <FolderZipOutlinedIcon />;
    } else if (fileType.startsWith("audio/")) {
      return <AudiotrackOutlinedIcon />;
    } else {
      return <InsertDriveFileOutlinedIcon />;
    }
  }, []);

  const handleDownloadFile = useCallback((id: string) => {
    window.open(getSharedFileDownloadLink(id), "_self");
  }, []);

  const getTitle = useCallback(
    (shareLink: ShareLink) => {
      if (shareLink && shareLink.title && shareLink.title.length > 0) {
        return shareLink.title;
      } else {
        let shareLinkFiles: ShareLinkFile[] | null =
          retrieveShareLinkFilesResult.data?.shareLinkFiles;
        if (shareLinkFiles) {
          if (shareLinkFiles.length > 1) {
            return (
              shareLinkFiles[0].fileName +
              t("and_n_more_files", { n: shareLinkFiles!!.length - 1 })
            );
          } else {
            return shareLinkFiles[0].fileName;
          }
        }
      }
    },
    [retrieveShareLinkFilesResult]
  );
  const handleSaveToMyCloud = useCallback(() => {
    if (!isUserLoggedIn) {
      dispatch(setPathAfterLogin(window.location.pathname));
      navigate(LOGIN);
      return;
    }
    setOpenSaveToCloudModal(true);
  }, [isUserLoggedIn]);

  const handleDownloadAll = useCallback(
    (fileIndex: number = 0) => {
      if (retrieveShareLinkFilesResult.data.shareLinkFiles) {
        let filesCount =
          retrieveShareLinkFilesResult.data.shareLinkFiles.length;
        if (fileIndex < filesCount) {
          setTimeout(() => {
            let id =
              retrieveShareLinkFilesResult.data.shareLinkFiles[fileIndex].id;
            window.open(getSharedFileDownloadLink(id), "_self");
            handleDownloadAll(fileIndex++);
          }, 500);
        }
      }
    },
    [retrieveShareLinkFilesResult.data]
  );

  const handleUnlockShareLink = useCallback(() => {
    setPasswordError(false);
    retrieveShareLink({
      variables: {
        password: passwordInput,
      },
    })
      .then((result) => {
        //link could be expired at this point... to be improved later
        if (!result.data || !result.data.shareLink) {
          setPasswordError(true);
        } else {
          setShareLinkLocked(false);
          retrieveShareLinkFiles({
            variables: {
              password: passwordInput,
            },
          });
        }
      })
      .catch((e) => {
        console.log("Caught an exception with the link" + e);
        setLinkExpiredOrNotExists(true);
      });
  }, [passwordInput]);

  if (shareLinkLocked) {
    return (
      <>
        <ShareLinkPageHeader />

        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "top",
            alignItems: "center",
            width: "100%",
            height: "100%",
            paddingTop: "10%",
          }}
        >
          <LockIcon
            sx={{
              color: "gray",
            }}
          />
          <TextField
            error={passwordError}
            helperText={passwordError ? t("password_invalid") : undefined}
            sx={{
              width: "60%",
              marginTop: theme.spacing(2),
              transition: theme.transitions.create("all", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            }}
            fullWidth
            disabled={retrieveShareLinkResult.loading}
            value={passwordInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPasswordInput(event.target.value);
            }}
            placeholder={t("enter_password_share_link_locked")}
            type={showPassword ? "text" : "password"}
            InputProps={{
              autoComplete: "off",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LoadingButton
            loading={retrieveShareLinkResult.loading}
            onClick={handleUnlockShareLink}
            variant="contained"
            sx={{
              marginTop: theme.spacing(2),
            }}
          >
            {t("unlock_action")}
          </LoadingButton>
        </Container>
      </>
    );
  }

  if (retrieveShareLinkResult.data && retrieveShareLinkResult.data.shareLink) {
    let shareLink: ShareLink = retrieveShareLinkResult.data.shareLink;
    return (
      <Box sx={{ bgcolor: "background.default" }}>
        <Helmet>
          <title
            style={{
              textTransform: "capitalize",
            }}
          >
            {getTitle(retrieveShareLinkResult.data.shareLink)}
          </title>
        </Helmet>
        <ShareLinkPageHeader />
        <Box
          ref={containerRef}
          sx={{
            px: theme.spacing(12),
            marginTop: theme.spacing(4),
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down("md")]: {
              px: theme.spacing(1),
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h3" sx={{}}>
                {getTitle(retrieveShareLinkResult.data.shareLink)}
              </Typography>
              {retrieveShareLinkResult.data.shareLink.message && (
                <Typography
                  variant="caption"
                  sx={{
                    maxWidth: "75%",
                    marginBottom: theme.spacing(1),
                  }}
                >
                  {retrieveShareLinkResult.data.shareLink.message}
                </Typography>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: theme.spacing(0.1),
                }}
              >
                <Link
                  sx={{
                    marginLeft: theme.spacing(1),
                    fontWeight: "bold",
                    fontSize: ".8rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleSaveToMyCloud();
                  }}
                >
                  <Stack
                    flexDirection="row"
                    sx={{
                      marginRight: theme.spacing(1),
                    }}
                  >
                    <img width="12px" height="12px" src={GoogleDriveIcon} />
                    <img width="12px" height="12px" src={OneDriveIcon} />
                    <img width="12px" height="12px" src={DropBoxIcon} />
                  </Stack>
                  {t("save_to_my_cloud_action")}
                </Link>
                {/*                 <Typography
                  variant="subtitle2"
                  sx={{
                    marginLeft: theme.spacing(1),
                    color: "gray",
                  }}
                >
                  •
                </Typography> */}
                {/*  <Link
                  sx={{
                    marginLeft: theme.spacing(1),
                    fontWeight: "bold",
                    fontSize: ".8rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleDownloadAll();
                  }}
                >
                  {t("download_all_action")}
                </Link>
 */}
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginLeft: theme.spacing(1),
                    color: "gray",
                  }}
                >
                  •
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "gray",
                    marginLeft: theme.spacing(0.5),
                  }}
                >
                  {shareLink.filesCount +
                    " " +
                    (shareLink.filesCount > 1
                      ? t("files_plural")
                      : t("file_singular"))}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginLeft: theme.spacing(1),
                    color: "gray",
                  }}
                >
                  •
                </Typography>

                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "gray",
                    marginLeft: theme.spacing(0.5),
                  }}
                >
                  {getSizeInHumeanReadableFormat(
                    retrieveShareLinkResult.data.shareLink.filesTotalSize
                  )}
                </Typography>

                <Typography
                  variant="subtitle2"
                  sx={{
                    marginLeft: theme.spacing(1),
                    color: "gray",
                  }}
                >
                  •
                </Typography>

                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "gray",
                    marginLeft: theme.spacing(0.5),
                  }}
                >
                  {t("expires_in", {
                    expiry: getRemainingTime(
                      retrieveShareLinkResult.data.shareLink.expiryDate,
                      t
                    ),
                  })}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Grid
            container
            spacing={1}
            sx={{
              overflowY: "auto",
              display: "flex",
              justifyContent: "start",
              px: theme.spacing(8),
              marginTop: theme.spacing(2),
              // justifyContent:"center"
              [theme.breakpoints.down("md")]: {
                px: theme.spacing(4),
                height: "100%",
              },
            }}
          >
            {retrieveShareLinkFilesResult.data &&
              retrieveShareLinkFilesResult.data.shareLinkFiles &&
              retrieveShareLinkFilesResult.data.shareLinkFiles.map(
                (shareLinkFile: ShareLinkFile) => {
                  return (
                    <>
                      <Grid
                        key={shareLinkFile.id}
                        sx={{
                          width: "100%",
                          alignItems: "center",
                          display: "flex",
                          [theme.breakpoints.down("sm")]: {
                            justifyContent: "center",
                            alignItems: "center",
                          },
                        }}
                        item
                        xs={6}
                        sm={4}
                        md={4}
                      >
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            boxShadow:
                              "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                            borderRadius: "8px",
                            my: theme.spacing(1),
                            width: "100%",

                            /* border:
      clickedUploadItem && clickedUploadItem.id == item.id
        ? `2px solid ${theme.palette.primary.main}`
        : "none", */
                          }}
                        >
                          {shareLinkFile.hasPreview ? (
                            <img
                              style={{
                                objectFit: "cover",
                              }}
                              height="200px"
                              width="100%"
                              src={getSharedFilePreview(shareLinkFile.id)}
                            />
                          ) : (
                            <Box
                              sx={{
                                height: "200px",
                                display: "flex",
                                flexDirection: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                background: theme.palette.primary.main,
                              }}
                            >
                              {getIconForFileType(shareLinkFile.fileType)}
                            </Box>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              // marginLeft: theme.spacing(1),
                              width: "100%",
                              px: theme.spacing(1),
                              py: theme.spacing(1),
                              overflow: "hidden",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                overflow: "hidden",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: ".9rem",
                                  fontWeight: "bold",
                                }}
                                noWrap
                              >
                                {shareLinkFile.fileName}
                              </Typography>
                              <Typography variant="caption">
                                {getSizeInHumeanReadableFormat(
                                  shareLinkFile.fileSize
                                )}
                                {" • " + shareLinkFile.fileType}
                              </Typography>
                            </Box>
                            <Box>
                              <Tooltip title={t("download_action")}>
                                <IconButton
                                  color="primary"
                                  onClick={() => {
                                    handleDownloadFile(shareLinkFile.id);
                                  }}
                                >
                                  <DownloadForOfflineOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    </>
                  );
                }
              )}
          </Grid>
        </Box>
        {openSaveToCloudModal && (
          <SaveSharedFileToCloud
            open={openSaveToCloudModal}
            shareLinkToken={params.token}
            handleModalClose={() => {
              setOpenSaveToCloudModal(false);
            }}
          />
        )}
      </Box>
    );
  }
  if (linkExpiredOrNotExists) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PageNotFound />
      </Box>
    );
  }
  return <></>;
};

export default React.memo(ShareLinkPage);
