import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useTranslation } from "react-i18next";
import { getTiers } from "shared/subscriptionPlans";
import {
  ToggleButton,
  ToggleButtonGroup,
  alpha,
  useTheme,
} from "@mui/material";
import { SubscriptionPlanIds } from "models/user";
import { useNavigate } from "react-router-dom";
import {
  PAYMENT_ANNUAL_BUSINESS,
  PAYMENT_ANNUAL_STANDARD,
  PAYMENT_MONTHLY_BUSINESS,
  PAYMENT_MONTHLY_STANDARD,
  PRICING,
  SIGNUP,
} from "navigation/Constants";
import { getUserCurrency, setUserCurrency } from "localstorage/localstorage";
import { DEFAULT_CURRENCY } from "configuration";
import {
  getCurrencySymbol,
  getPriceInLocalCurrency,
  getYearlyPricePerMonthInLocalCurrency,
} from "shared/currencies";
import { fetchUserCurrency } from "api/geoipApi";

const RECURRENCE_ANNUAL = "0";
const RECURRENCE_MONTHLY = "1";

export default function Pricing() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [selectedRecurrence, setSelectedRecurrence] = React.useState(
    RECURRENCE_ANNUAL
  );

  const [localCurrency, setLocalCurrency] = React.useState(
    getUserCurrency() ? getUserCurrency() : DEFAULT_CURRENCY
  );

  React.useEffect(() => {
    const userCurrencyFromLocal = getUserCurrency();
    if (!userCurrencyFromLocal) {
      fetchUserCurrency().then((result: any) => {
        /* console.log("currency code " + result.currency.code)
        console.log("currency symbol " + result.currency.symbol) */
        const currency = result.currency.code;
        setUserCurrency(currency);
        setLocalCurrency(currency);
      });
    }
  }, []);

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 12, sm: 6 },
        pb: { xs: 2, sm: 4 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
        ".Mui-selected": {
          backgroundColor: theme.palette.primary.main,
          background: theme.palette.primary.main,
          backgroundImage: theme.palette.primary.main,
          color: "transparent",
          "&:hover": {
            backgroundColor: `${alpha(theme.palette.primary.main, 0.9)}`,
            background: `${alpha(theme.palette.primary.main, 0.9)}`,
            backgroundImage: theme.palette.primary.main,
            color: "transparent",
          },
        },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "center", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          {t("pricing_title")}
        </Typography>
        {/*  <Typography variant="body1" color="text.secondary">
          Quickly build an effective pricing table for your potential customers
          with this layout. <br />
          It&apos;s built with default Material UI components with little
          customization.
        </Typography> */}
      </Box>

      <ToggleButtonGroup
        sx={{
          borderRadius: "24px",
        }}
        size="small"
        exclusive
        value={selectedRecurrence}
        onChange={(
          event: React.MouseEvent<HTMLElement>,
          newRecurrence: string
        ) => {
          console.log("new recurrence is " + newRecurrence);
          if (newRecurrence == null) {
            return;
          }
          setSelectedRecurrence(newRecurrence);
        }}
      >
        <ToggleButton
          sx={{
            borderRadius: "24px",
          }}
          value={RECURRENCE_MONTHLY}
          key={RECURRENCE_MONTHLY}
        >
          <Typography
            sx={{
              px: theme.spacing(3),
              py: theme.spacing(1),
              borderRadius: "24px",
              background:
                selectedRecurrence === RECURRENCE_MONTHLY
                  ? theme.palette.primary.main
                  : "transparent",
              color:
                selectedRecurrence === RECURRENCE_MONTHLY ? "white" : "gray ",
            }}
            variant="subtitle2"
          >
            {t("monthly_pricing_title")}
          </Typography>
        </ToggleButton>
        <ToggleButton
          sx={{
            borderRadius: "24px",
          }}
          value={RECURRENCE_ANNUAL}
          key={RECURRENCE_ANNUAL}
        >
          <Box
            sx={{
              px: theme.spacing(2),
              display: "flex",
              flexDirection: "center",
            }}
          >
            <Typography
              sx={{
                px: theme.spacing(3),
                py: theme.spacing(1),
                borderRadius: "24px",
                background:
                  selectedRecurrence === RECURRENCE_ANNUAL
                    ? theme.palette.primary.main
                    : "transparent",
                color:
                  selectedRecurrence === RECURRENCE_ANNUAL ? "white" : "gray ",
              }}
              variant="subtitle2"
            >
              {t("annual_pricing_title")}
            </Typography>
          </Box>
        </ToggleButton>
      </ToggleButtonGroup>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {getTiers(t).map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 4,
                border: tier.title === "Standard" ? "1px solid" : undefined,
                borderColor:
                  tier.title === "Standard" ? "primary.main" : undefined,
                background:
                  tier.title === "Standard"
                    ? "linear-gradient(#033363, #021F3B)"
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: tier.title === "Standard" ? "grey.100" : "",
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === "Standard" && tier.subheader && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === "light" ? "" : "none",
                        backgroundColor: "primary.contrastText",
                        "& .MuiChip-label": {
                          color: "primary.dark",
                        },
                        "& .MuiChip-icon": {
                          color: "primary.dark",
                        },
                      }}
                    />
                  )}
                </Box>
                {selectedRecurrence == RECURRENCE_ANNUAL ? (
                  <>
                    {tier.title !== t("tier_free_title") && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                          color:
                            tier.title === "Standard" ? "grey.50" : undefined,
                        }}
                      >
                        <Typography
                          sx={{
                            textDecoration: "line-through",
                            color: "#d3d3d3",
                          }}
                          component="h3"
                          variant="h2"
                        >
                          {/*  {"  "}${tier.monthlyPricing + "  "} */}
                          {getCurrencySymbol(localCurrency)}
                          {getPriceInLocalCurrency(
                            localCurrency,
                            tier.id,
                            false
                          )}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        color:
                          tier.title === "Standard" ? "grey.50" : undefined,
                      }}
                    >
                      <Typography component="h3" variant="h2">
                        {/*  ${tier.yearlyPricing} */}
                        {getCurrencySymbol(localCurrency)}
                        {getYearlyPricePerMonthInLocalCurrency(
                          localCurrency,
                          tier.id
                        )}
                      </Typography>
                      <Typography component="h3" variant="h6">
                        &nbsp; /{t("month")}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      color: tier.title === "Standard" ? "grey.50" : undefined,
                    }}
                  >
                    <Typography component="h3" variant="h2">
                      {getCurrencySymbol(localCurrency)}
                      {getPriceInLocalCurrency(localCurrency, tier.id, false)}
                    </Typography>
                    <Typography component="h3" variant="h6">
                      &nbsp; /{t("month")}
                    </Typography>
                  </Box>
                )}

                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: "grey.500",
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === "Standard"
                            ? "primary.light"
                            : "primary.main",
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.title === "Standard" ? "grey.200" : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant as "outlined" | "contained"}
                  component="a"
                  target="_blank"
                  onClick={() => {
                    if (tier.id == SubscriptionPlanIds.FREE) {
                      navigate(SIGNUP);
                    }
                    if (selectedRecurrence == RECURRENCE_ANNUAL) {
                      if (tier.id == SubscriptionPlanIds.STANDARD) {
                        navigate(PAYMENT_ANNUAL_STANDARD);
                      } else {
                        navigate(PAYMENT_ANNUAL_BUSINESS);
                      }
                    }
                    if (selectedRecurrence == RECURRENCE_MONTHLY) {
                      if (tier.id == SubscriptionPlanIds.STANDARD) {
                        navigate(PAYMENT_MONTHLY_STANDARD);
                      } else {
                        navigate(PAYMENT_MONTHLY_BUSINESS);
                      }
                    }
                  }}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      
     
    </Container>
  );
}
