import axios, { AxiosResponse } from "axios";
import { SERVER_URL } from "../configuration";
import { UserObj } from "models/user";
import { getDeviceName, getClientUniqueId } from "shared/dataUtils";
import { DEBUG } from "configuration";
import Cookies from "js-cookie";

/**
 * We will use this call to first check if the upload link is not expired nor disabled
 * @returns
 */

export interface ShareLlinkCheckResult {
  disabled: boolean;
  expired: boolean;
  locked: boolean;
}

export enum UploadLinkInvalidErrors {
  UPLOAD_LINK_EXPIRED = 1,
  UPLOAD_LINK_DISABLED = 2,
}

export const checkShareLinkStatus = async (
  token: string
): Promise<ShareLlinkCheckResult> => {
  if (DEBUG) {
    console.log("checkShareLinkStatus: ");
  }
  // const body = JSON.stringify({ token: token });
  try {
    let res = await axios.get(SERVER_URL + "/sharelink-info/" + token, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (DEBUG) {
      console.log("Got user data is " + JSON.stringify(res));
    }
    return {
      disabled: res.data.disabled,
      expired: res.data.expired,
      locked: res.data.locked,
    };
  } catch (e) {
    throw e;
  }
};
